import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';

import Header2 from './../../Layout/Header2';
import Footer2 from './../../Layout/Footer2';
// import SideNav from './../../Element/SideNav';
import VideoPopup from './../../Element/VideoPopup';


import Slider1 from './../../Element/Slider1';
import Mapview from './../../Element/Mapview';
import SpecificationsBlog from './SpecificationsBlog';
import SitePlanTab from './SitePlanTab';
import ContactUs1 from '../Contact/ContactUs1'
import SwiperSlider from '../Aboutus/SwiperSlider';
//Images
import SliderImg from './../../../images/maricacon/header-1.jpeg';

import alberca from './../../../images/maricacon/alberca.jpeg';
import gim from './../../../images/maricacon/ame/gim.jpeg';
import infa from './../../../images/maricacon/ame/infa.jpeg';
import coworking from './../../../images/maricacon/ame/coworking.jpeg';
import asador from './../../../images/maricacon/ame/asador.jpeg';
import palapas from './../../../images/maricacon/ame/palapas.jpeg';
import ciclo from './../../../images/maricacon/ame/ciclo.jpeg';
import cami from './../../../images/maricacon/ame/cami.jpeg';
import rof from './../../../images/maricacon/pagina/roftop.jpg';
import sitemap2 from './../../../images/map3.JPG';
import sitemap3 from './../../../images/about/about-1.jpeg';


import services1 from './../../../images/services/pic1.jpg';
import services6 from './../../../images/services/pic6.jpg';
import services7 from './../../../images/services/pic7.jpg';
import services8 from './../../../images/services/pic8.jpg';
import services9 from './../../../images/services/pic9.jpg';


class Index1 extends React.Component {
	componentDidMount() {
		new WOW.WOW().init();

		var GalleryCategory = document.querySelectorAll('.gallery-category .items');
		var GalelryMedia = document.querySelectorAll('.gallery-img img');

		var fch = [].slice.call(GalleryCategory);
		var fcMedia = [].slice.call(GalelryMedia);


		for (var y = 0; y < fch.length; y++) {
			fch[y].addEventListener('mouseenter', function () {
				galleryActive(this);
			});
		}

		function galleryActive(current) {
			fcMedia.forEach(el => el.classList.remove('active'));

			setTimeout(() => {
				var dataImageBx = current.getAttribute('data-image-bx');
				document.querySelector('#' + dataImageBx).classList.add('active');
			}, 100);
		}
	}
	render() {
		return (
			<Fragment>
				<Header2 />
				{/* <!-- Main Slider --> */}
				<div className="page-content bg-white">
					<div className="banner-three " style={{ backgroundImage: "url(" + SliderImg + ")", backgroundSize: "cover" }} data-content="HOME" id="sidenav_home" >
						<div className="container">
							<div className="row align-items-center banner-inner " >
								<div className="col-md-6">
									<div className="content-blog">
										<div className="banner-content">
											<h1 className=" title  m-b20">Vive tu<br /> inversión En  <br /> Mazatlán </h1>
											<a href="https://wa.me/526691028383" target="_blank" className=" btn btn-primary">Contactar Ahora</a>
										</div>
										<VideoPopup />
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Main Slider End--> */}

					{/* Section-1  Start*/}
					<section className="amenities-area">
						<Slider1 />
					</section>
					{/* Section-1  End*/}

					{/* Section-2  Start*/}
					<section className="content-inner-2" data-content="AMENIDADES" id="sidenav_specifications">
						<div className="container">
							<div className="section-head head-col">
								<h2 className="title" >Desarrollo con más amenidades en Mazatlán</h2>
								<p>15,000 m2 de áreas con amenidades</p>
							</div>
						</div>
						<div className="row faqs-box spno">
							<div className="col-md-8 col-lg-8 col-xl-8">
								<div className="faq-media">
									<Link to={"#"}><img src={alberca} id="Capmap1" className="active" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={gim} id="Capmap2" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={rof} id="Capmap3" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={coworking} id="Capmap4" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={asador} id="Capmap5" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={palapas} id="Capmap6" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={ciclo} id="Capmap7" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={cami} id="Capmap8" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={rof} id="Capmap9" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
									<Link to={"#"}><img src={rof} id="Capmap10" alt="MarinaCerritos | Es un desarrollo en condominio de 8 torres" /></Link>
								</div>
							</div>
							<SpecificationsBlog />
						</div>
					</section>
					{/* Section-2 End */}
					{/* Section-3 */}
					<section className="content-inner " data-content="SISTEMA" id="sidenav_aboutUs">
						<div className="about-bg"></div>
						<div className="container">
							<div className="row">
								<div className="col-md-6 col-lg-6">
									<div style={{padding:20}}>
									<img width="80%" src={sitemap2} alt="MarinaCerritos | Departamentos en Marina Cerritos"/>
									</div>
								</div>
								<div className="col-md-7 col-lg-6">
									<div className="section-head">
										<h2 className="title" >NUESTRO SISTEMA INTELIGENTE</h2>
										<div className="dlab-separator bg-primary " ></div>
										<h4 className="mb-4" >VEA POR QUÉ NUESTROS RESIDENTES LLAMAN A NUESTRO HOGAR INTELIGENTE..</h4>
										<p >
										Construimos departamentos de alta calidad,  Cada unidad se entregará con la tecnología del SISTEMA INTELIGENTE con el cual usted podrá tener el control de su departamento desde cualquier parte del mundo, ya que por medio de una aplicación podrá asignar códigos de accesos y cancelarlos en el momento que lo deseen, controlar por completo sus minisplis ( prender, apagar y controlar la temperatura ), controlar la luz del departamento, de esta manera le ofrecemos más tranquilidad al tenerlo monitoreado las 24 horas.
										</p>
									</div>
									<a href="tel:+6691028383" className="call-planner"><i className="la la-phone-volume"></i>+669 102 8383</a>
								</div>
							</div>
						</div>
					</section>
					{/* Section-3 End*/}
					{/* Section-3 */}
					<section className="content-inner about-box" data-content="SOBRE NOSOTROS" id="sidenav_aboutUs">
						<div className="about-bg"></div>
						<div className="container">
							<div className="row">
								<div className="col-md-6 col-lg-6">
									<div className="section-head">
										<h2 className="title" >RESUMEN DE PROYECTO</h2>
										<div className="dlab-separator bg-primary " ></div>
										<p >
										Marina cerritos es un desarrollo en condominio de 8 torres con un <strong>ROOF TOP</strong> privado por torre, el complejo estará rodeado de áreas verdes y contará con el <strong>MAYOR NUMERO DE AMENIDADES EN MAZATLÁN,</strong> además de estar a 500 m de la playa y en una de las mejores zonas de Mazatlán con muy <strong>ALTA PLUSVALIA.</strong>
										</p>
										<p>
									50 departamentos por edificio, dividido en 5 niveles, cada edificio contará con su propio elevador camillero y sus propias amenidades privadas en el Roff top para tu comodidad. 
										</p>
										<p>
										<strong>Para su seguridad y tranquilidad</strong> entregaremos cada departamento equipado con un SISTEMA INTELIGENTE que les permitirá monitorear y controlar su propiedad desde cualquier parte del mundo, el <strong>SISTEMA INTELIGENTE</strong> le dará la opción de prender, apagar y controlar la temperatura de sus minisplit, también prender, apagar y controlar la intensidad de sus luces y para <strong>controlar los accesos</strong> usted podrá asignar códigos de entrada y cancelarlos cuando usted lo desee ya que cada departamento contará con su chapa electrónica que les permitirá tener acceso por huella, código, tarjeta o por llave.
										</p>
									</div>
									{/* <Link to={"/about-us-1"} className="btn btn-primary " >About Us</Link> */}
								</div>
								<div className="col-md-6 col-lg-6">
									<div style={{padding:20}}>
									<img src={sitemap3} alt="MarinaCerritos | Torre de Departamentos en Mazatlán Sinaloa"/>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* Section-3 End*/}
					{/* Section-4 Start */}
					<section className="content-inner" data-content="MASTER PLAN" id="sidenav_masterPlan">
						<SitePlanTab />
					</section>
					{/* Section-4 End*/}
					{/* Section-5 Start*/}
					<SwiperSlider/>
					{/* Section-5 End*/}
					{/* Section-6 Start*/}
					{/* <section className="content-inner-2" data-content="SERVICIOS" id="sidenav_ourServices">
						<div className="container">
							<div className="row align-items-end section-head" >
								<div className="col-md-6">
									<h2 className="title">Servicios & Construcción</h2>
									<div className="dlab-separator bg-primary"></div>
									<p className="mb-3 mb-md-0">
										Los mejores servicios, el complejo con mas servicios de Mazatlán.
									</p>
								</div>
							</div>
						</div>
						<div className="service-area" >
							<div className="row spno service-row">
								<div className="col ">
									<div className="service-box">
										<div className="media">
											<img src={services1} alt="MarinaCerritos | MARINA CERRITOS es un desarrollo en condominio de 8 torres" />
										</div>
										<div className="info">
											<Link to={"#"}><h4 className="title">Agua Potable 24 HRS</h4></Link>
										</div>
									</div>
								</div>
								<div className="col" >
									<div className="service-box">
										<div className="media">
											<img src={services6} alt="MarinaCerritos | MARINA CERRITOS es un desarrollo en condominio de 8 torres" />
										</div>
										<div className="info">
											<Link to={"#"}><h4 className="title">Elevadores</h4></Link>
										</div>
									</div>
								</div>
								<div className="col" >
									<div className="service-box">
										<div className="media">
											<img src={services7} alt="MarinaCerritos | MARINA CERRITOS es un desarrollo en condominio de 8 torres" />
										</div>
										<div className="info">
											<Link to={"#"}><h4 className="title">Parking</h4></Link>
										</div>
									</div>
								</div>
								<div className="col">
									<div className="service-box">
										<div className="media">
											<img src={services8} alt="MarinaCerritos | MARINA CERRITOS es un desarrollo en condominio de 8 torres" />
										</div>
										<div className="info">
											<Link to={"#"}><h4 className="title">Antiincendios</h4></Link>
										</div>
									</div>
								</div>
								<div className="col" >
									<div className="service-box">
										<div className="media">
											<img src={services9} alt="MarinaCerritos | MARINA CERRITOS es un desarrollo en condominio de 8 torres" />
										</div>
										<div className="info">
											<Link to={"#"}><h4 className="title">Seguridad</h4></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section> */}
					{/* Section-6 End*/}
					{/* Section-7 Start*/}
					{/* <section className="content-inner-2" data-content="NEWS & EVENT" id="sidenav_newsEvent">	
						<EventSection />
					</section>	 */}
					{/* Section-7 End*/}

					<Mapview />
					<ContactUs1 />
				</div>
				<Footer2 />
				<a
        href="https://wa.me/526691028383"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
			</Fragment>
		)
	}
}

export default Index1;